import React, { Fragment } from "react"

import SplitBackground from "../components/SplitBackground"
import HorizontalScrollIndicator from "../components/HorizontalScrollIndicator"
import Helmet from "../components/Helmet"
import Container from "../components/Container"

const Error404 = () => (
  <Fragment>
    <SplitBackground />
    <HorizontalScrollIndicator />
    <Helmet />
    <Container>
      <h1 className="text-center">Erreur 404</h1>
      <div className="text-center">
        <p>Oups... la page est introuvable.</p>
        <br />
        <button
          className="button"
          type="button"
          onClick={() => window.location.replace("/")}
        >
          Retour au site
        </button>
      </div>
    </Container>
  </Fragment>
)

export default Error404
